import firebase from 'firebase';
import { v4 as uuid } from 'uuid';
type UploadFile = {
  data: string;
  file: File;
  fileName: string;
  id: number;
  mimetype: string;
  type: string;
};
// files: array of files
// parents: storage path excluding filename
async function storageHandler(files: Array<UploadFile>, parents: string): Promise<{ name: string; url: string }> {
  const imagesUploadHandler: Array<UploadTask> = files.map((img: UploadFile): Array<firebase.storage.UploadTask> => {
    const extension: string = img.fileName.slice(img.fileName.lastIndexOf('.'));
    const uploadPath: string = `${parents}/${uuid()}${extension}`;
    return firebase.storage().ref().child(uploadPath).put(img.file);
  });

  try {
    const storageResult: Array<firebase.storage.UploadTaskSnapshot> = await Promise.all(imagesUploadHandler);
    const imagesData: Array<{ name: string; url: string }> = [];
    for (const result of storageResult) {
      const downloadUrl: string = await result.ref.getDownloadURL();
      imagesData.push({
        name: result.metadata.name,
        url: downloadUrl,
      });
    }

    return imagesData;
  } catch (error) {
    console.log(error);
  }
}

export { storageHandler };
