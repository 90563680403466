<template>
  <!-- Default event is being set to @change, since @input causes a lot of
	performance issues when there's too much input on a given form -->
  <v-textarea
    v-on="clisteners"
    :label="label"
    :value="modelValue"
    @change="!regular && $emit('change', $event)"
    @input="regular && $emit('change', $event)"
    :dense="dense"
    :error-messages="errors"
    v-bind="$attrs"
    :rows="textRows"
    :no-resize="noResize"
  >
    <template v-slot:append-outer>
      <slot name="append-outer"></slot>
    </template>
    <template v-slot:label>
      <slot name="label"
        >{{ label }}
        <label v-if="required" class="red--text">*</label>
      </slot>
    </template>
    <template v-slot:append>
      <slot name="append"></slot>
    </template>
  </v-textarea>
</template>

<script lang="ts">
export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    regular: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    textRows: {
      type: Number,
      default: 2,
    },
    noResize: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    clisteners: function () {
      const { change, input, ...listeners } = this.$listeners;
      return listeners;
    },
  },
};
</script>
