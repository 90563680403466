import { getCustomClaimsFromCurrentUser } from '@/services/auth/AuthService';
import { getRecord } from '@/services/firebase/FirestoreService';

//
export const checkPermissions = async (resourceAccess = {}) => {
  const customClaims = await getCustomClaimsFromCurrentUser();
  if (!customClaims) return;
  const roleRecord = await getRecord({
    collectionName: 'roles',
    id: customClaims.role,
  });

  const canAccessResource = (resourceAccess, roleResourceAccess) => {
    for (key in resourceAccess) {
      if (!roleResourceAccess[key]) {
        return false;
      }

      if (!Array.isArray(roleResourceAccess[key])) {
        return false;
      }

      for (const item of resourceAccess[key]) {
        if (!roleResourceAccess[key].includes(item)) {
          return false;
        }
      }

      return true;
    }
  };

  return canAccessResource(resourceAccess, roleRecord.resourceAccess);
};
