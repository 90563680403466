// import { PermissionsService } from '@/services/auth/permissionsService';
// import { ServiceContainer } from '@/services/serviceContainer';

// All App Components
const LoginComponent = () => import('@/components/LoginComponent.vue');
const home = () => import('@/components/home.vue');
// const QuickCode = () => import('@/components/quick_codes/QuickCode.vue');
// const QuickCodeArchived = () => import('@/components/quick_codes/QuickCodeArchived.vue');
// const ViewQuickCode = () => import('@/components/quick_codes/ViewQuickCode.vue');
// const AddQuickCode = () => import('@/components/quick_codes/AddQuickCode.vue');
// const EditQuickCode = () => import('@/components/quick_codes/EditQuickCode.vue');
// const DuplicateQuickCode = () => import('@/components/quick_codes/DuplicateQuickCode.vue');
const Opportunities = () => import('@/components/spaces/Opportunities.vue');
const Spaces = () => import('@/components/spaces/Spaces.vue');
const ViewSpace = () => import('@/components/spaces/ViewSpace.vue');
const ViewSpaceWithQC = () => import('@/components/spaces/ViewSpaceWithQC.vue');
const AddSpace = () => import('@/components/spaces/AddSpace.vue');
const AddSpaceQCSelection = () => import('@/components/spaces/AddSpaceQCSelection.vue');
const AddSpaceSelectedQC = () => import('@/components/spaces/AddSpaceSelectedQC.vue');
const EditSpace = () => import('@/components/spaces/EditSpace.vue');
const EditSpaceQuick = () => import('@/components/spaces/EditSpaceQuick.vue');
const comingSoon = () => import('@/components/coming_soon/comingSoon.vue');
const DuplicateSpaceManual = () => import('@/components/spaces/DuplicateSpaceManual.vue');
const DuplicateSpaceQuick = () => import('@/components/spaces/DuplicateSpaceQuick.vue');
const ControlProducts = () => import('@/components/products/viewProducts/ControlProducts.vue');
const AccessoryProducts = () => import('@/components/products/viewProducts/AccessoryProducts.vue');
const PrimaryProducts = () => import('@/components/products/viewProducts/PrimaryProducts.vue');
const ViewProduct = () => import('@/components/products/ViewProduct.vue');
const CreateAccessoryProduct = () => import('@/components/products/createProducts/CreateAccessoryProduct.vue');
const CreatePrimaryProduct = () => import('@/components/products/createProducts/CreatePrimaryProduct.vue');
const CreateControlProduct = () => import('@/components/products/createProducts/CreateControlProduct.vue');
const CreateSolarProduct = () => import('@/components/products/createProducts/CreateSolarProduct.vue');
const EditAccessoryProduct = () => import('@/components/products/editProducts/EditAccessoryProduct.vue');
const EditControlProduct = () => import('@/components/products/editProducts/EditControlProduct.vue');
const EditPrimaryProduct = () => import('@/components/products/editProducts/EditPrimaryProduct.vue');
const DuplicateAccessoryProduct = () => import('@/components/products/duplicateProducts/DuplicateAccessoryProduct.vue');
const DuplicateControlProduct = () => import('@/components/products/duplicateProducts/DuplicateControlProduct.vue');
const DuplicatePrimaryProduct = () => import('@/components/products/duplicateProducts/DuplicatePrimaryProduct.vue');
// const CreateDesign = () => import('@/components/designs/CreateDesign.vue');
// const DesignOpportunities = () => import('@/components/designs/DesignOpportunities.vue');
// const DesignSpaces = () => import('@/components/designs/DesignSpaces.vue');
// const NewDesign = () => import('@/components/designs/NewDesign.vue');
// const DesignAccessoryProduct = () => import('@/components/designs/DesignAccessoryProduct.vue');
// const DesignControlProduct = () => import('@/components/designs/DesignControlProduct.vue');
// const DesignPrimaryProduct = () => import('@/components/designs/DesignPrimaryProduct.vue');
// const ViewDesigns = () => import('@/components/designs/ViewDesigns.vue');
// const ViewSelectedDesign = () => import('@/components/designs/ViewSelectedDesign.vue');
// const EditSelectedDesign = () => import('@/components/designs/EditSelectedDesign.vue');
// const DuplicateSelectedDesign = () => import('@/components/designs/DuplicateSelectedDesign.vue');
const tempPrimaryProducts = () => import('@/components/products/viewProducts/tempPrimaryProducts.vue');
// const DesignBox = () => import('@/components/designs/DesignBox.vue');
// const ECMs = () => import('@/components/designs/ECMs.vue');
// const BuildECMs = () => import('@/components/designs/BuildECMs.vue');
const ForgotPassword = () => import('@/components/ForgotPassword.vue');
const MissingData = () => import('@/components/spaces/MissingData.vue');
const Installations = () => import('@/components/spaces/Installations.vue');
// const InstallationMap = () => import('@/components/spaces/InstallationMap.vue');
// const Designs = () => import('@/components/designs/Designs.vue');
// const PMVerification = () => import('@/components/PMVerification.vue');
const ProductSummary = () => import('@/components/spaces/ProductSummary.vue');
const PurchaseOrderForm = () => import('@/components/procurement/PurchaseOrderForm.vue');
const PurchaseOrders = () => import('@/components/procurement/PurchaseOrders.vue');
const POShipmentForm = () => import('@/components/procurement/POShipmentForm.vue');
const Shipments = () => import('@/components/procurement/Shipments.vue');
const BuildingList = () => import('@/components/buildings/BuildingList.vue');
const BuildingForm = () => import('@/components/buildings/BuildingForm.vue');
// const Profitability = () => import('@/components/accounting/Profitability.vue');

// Daily Report imports
const WorkReports = () => import('@/pages/field_operations/work_reports/WorkReports.vue');
const WorkReport = () => import('@/pages/field_operations/work_reports/work_report/WorkReport.vue');
const WorkReportForm = () => import('@/pages/field_operations/work_reports/work_report_form/WorkReportForm.vue');

// Equipment Inspections
// const EquipmentInspections = () => import('@/pages/field_operations/equipment_inspections/EquipmentInspections.vue');
// const EquipmentInspectionForm = () => import('@/pages/field_operations/equipment_inspections/form/EquipmentInspectionForm.vue');

// Weekly Overview imports
// const WeeklyOverviews = () => import('@/pages/field_operations/weekly_overviews/WeeklyOverviews.vue');
// const WeeklyOverviewForm = () => import('@/pages/field_operations/weekly_overviews/weekly_overview_form/WeeklyOverviewForm.vue');

// Vehicles imports
// const Vehicles = () => import('@/pages/field_operations/vehicles/Vehicles.vue');
// const VehicleForm = () => import('@/pages/field_operations/vehicles/vehicle_form/VehicleForm.vue');

// Equipment imports
// const Equipments = () => import('@/pages/field_operations/equipments/Equipments.vue');
// const EquipmentForm = () => import('@/pages/field_operations/equipments/form/EquipmentForm.vue');

// Accounting - Vacation Request imports
// const VacationRequests = () => import('@/pages/accounting/vacation_requests/VacationRequests.vue');
// const VacationRequestForm = () => import('@/pages/accounting/vacation_requests/vacation_request_form/VacationRequestForm.vue');

// HR - Quarterly Evaluation imports
const QuarterlyEvaluations = () => import('@/pages/operations/human_resources/quarterly_evaluation/QuarterlyEvaluations.vue');
const QuarterlyEvaluationForm = () =>
  import('@/pages/operations/human_resources/quarterly_evaluation/quarterly_evaluation_form/QuarterlyEvaluationForm.vue');
const QuarterlyEmployeeReport = () =>
  import('@/pages/operations/human_resources/quarterly_evaluation/quarterly_employee_report/QuarterlyEmployeeReport.vue');

// Checklist module imports
// const ChecklistRecords = () => import('@/pages/operations/human_resources/checklists/pages/records/ChecklistRecords.vue');
// const ChecklistRecord = () => import('@/pages/operations/human_resources/checklists/pages/records/ChecklistRecord.vue');
// const MyChecklistTasks = () => import('@/pages/operations/human_resources/checklists/pages/my_tasks/MyTasks.vue');
// const ChecklistTemplate = () => import('@/pages/operations/human_resources/checklists/pages/templates/ChecklistTemplate.vue');
// const ChecklistTemplates = () => import('@/pages/operations/human_resources/checklists/pages/templates/ChecklistTemplates.vue');

// Construction Reporting inputs
const ConstructionReportForm = () => import('@/pages/field_operations/construction_reporting/form/ConstructionReportForm.vue');

// Construction Reporting tables
const ConstructionReporting = () => import('@/pages/field_operations/construction_reporting/ConstructionReporting.vue');

// Accounting seccion's Time Entries and Timesheets pages
const TimeEntriesAndExpenses = () => import('@/pages/accounting/time_entries/TimeEntriesAndExpenses.vue');
const Timesheets = () => import('@/pages/accounting/timesheets/Timesheets.vue');

// Labor Costing
// const LaborCosting = () => import('@/pages/accounting/labor_costing/LaborCosting.vue');

// Opportunity Time Dashboard
// const OpportunityTimeDashboard = () => import('@/pages/accounting/time_entries/opportunity_time_dashboard/OpportunityTimeDashboard.vue');

// Payables
const APRecordForm = () => import('@/components/accounting/APRecords/APRecordForm.vue');
const APRecordsList = () => import('@/components/accounting/APRecords/APRecordsList.vue');

// Customer Relationships Management - Accounts
const Accounts = () => import('@/pages/customer_relationships_management/accounts/Accounts.vue');
const AccountForm = () => import('@/pages/customer_relationships_management/accounts/account_form/AccountForm.vue');

// Customer Relationships Management - Facilities
const Facilities = () => import('@/pages/customer_relationships_management/facilities/Facilities.vue');
const FacilityForm = () => import('@/pages/customer_relationships_management/facilities/facility_form/FacilityForm.vue');

// Customer Relationships Management - Contact
const Contacts = () => import('@/pages/customer_relationships_management/contacts/Contacts.vue');
const ContactForm = () => import('@/pages/customer_relationships_management/contacts/contact_form/ContactForm.vue');

// Customer Relationships Management - Opportunity
const SalesforceOpportunities = () => import('@/pages/customer_relationships_management/opportunities/Opportunities.vue');
const SalesforceOpportunityForm = () =>
  import('@/pages/customer_relationships_management/opportunities/opportunity_form/OpportunityForm.vue');

// Customer Relationships Management - Designs
// const SalesforceDesigns = () => import('@/pages/customer_relationships_management/designs/Designs.vue');
// const SalesforceDesignForm = () => import('@/pages/customer_relationships_management/designs/design_form/DesignForm.vue');

// Customer Relationships Management - Services
// const Services = () => import('@/pages/customer_relationships_management/services/Services.vue');
// const ServiceForm = () => import('@/pages/customer_relationships_management/services/service_form/ServiceForm.vue');

// Customer Relationships Management - Project Engines
// const ProjectEngines = () => import('@/pages/customer_relationships_management/project_engines/ProjectEngines.vue');
// const ProjectEngineForm = () =>
//   import('@/pages/customer_relationships_management/project_engines/project_engine_form/ProjectEngineForm.vue');

// Customer Relationships Management - Pre-C
// const PreContractingRecords = () => import('@/pages/customer_relationships_management/pre-contracting/PreContractingRecords.vue');
// const PreContractingForm = () =>
// import('@/pages/customer_relationships_management/pre-contracting/pre-contracting_form/PreContractingForm.vue');

// Customer Relationships Management - Contracted
// const ContractedRecords = () => import('@/pages/customer_relationships_management/contracted/ContractedRecords.vue');
// const ContractedForm = () => import('@/pages/customer_relationships_management/contracted/contracted_form/ContractedForm.vue');

// Customer Relationships Management - Accounting
// const AccountingRecords = () => import('@/pages/customer_relationships_management/accounting/AccountingRecords.vue');
// const AccountingForm = () => import('@/pages/customer_relationships_management/accounting/accounting_form/AccountingForm.vue');

// Customer Relationships Management - SOPs
const SOPs = () => import('@/pages/customer_relationships_management/sops/SOPs.vue');
const SOPForm = () => import('@/pages/customer_relationships_management/sops/sop_form/SOPForm.vue');

// Customer Relationships Management - Proposal Request
// const ProposalRequests = () => import('@/pages/customer_relationships_management/proposal_requests/ProposalRequests.vue');
// const ProposalRequestForm = () =>
//   import('@/pages/customer_relationships_management/proposal_requests/proposal_request_form/ProposalRequestForm.vue');

// Customer Relationships Management - Work Orders
// const WorkOrders = () => import('@/pages/customer_relationships_management/work_orders/WorkOrders.vue');
// const WorkOrderForm = () => import('@/pages/customer_relationships_management/work_orders/work_order_form/WorkOrderForm.vue');

// Customer Relationships Management - Reports
// const Reports = () => import('@/pages/customer_relationships_management/reports/Reports.vue');

// Customer Relationships Management - Returns
// const Returns = () => import('@/pages/customer_relationships_management/returns/Returns.vue');
// const ReturnForm = () => import('@/pages/customer_relationships_management/returns/return_form/ReturnForm.vue');

// Employee Handbook
const EmployeeHandbook = () => import('@/pages/operations/human_resources/employee_handbook/EmployeeHandbook.vue');
const DepartmentOfLaborNotifications = () =>
  import('@/pages/operations/human_resources/department_of_labor_notifications/DepartmentOfLaborNotifications.vue');
const OshaForm = () => import('@/pages/operations/human_resources/osha_form/OshaForm.vue');

// Termination Form
const TerminationForm = () => import('@/pages/operations/human_resources/termination_form/TerminationForm.vue');
const TerminationRecords = () => import('@/pages/operations/human_resources/termination_records/TerminationRecords.vue');

// Flight Requests
// const FlightRequestForm = () => import('@/pages/operations/flight_requests/flight_request_form/FlightRequestForm.vue');
// const FlightRequests = () => import('@/pages/operations/flight_requests/flight_requests/FlightRequests.vue');

// Tool Log
// const ToolLogForm = () => import('@/pages/operations/tool_logs/tool_log_form/ToolLogForm.vue');
// const ToolLogs = () => import('@/pages/operations/tool_logs/ToolLogs.vue');

// Employee Apparel
// const EmployeeApparel = () => import('@/pages/operations/employee_apparel/EmployeeApparel.vue');

// Admin Portal (Roles)
const Roles = () => import('@/pages/admin_portal/roles/Roles.vue');
const RoleForm = () => import('@/pages/admin_portal/roles/role_form/RoleForm.vue');

// Users
const UserForm = () => import('@/pages/admin_portal/users/user_form/UserForm.vue');
const AllUsers = () => import('@/pages/admin_portal/users/AllUsers.vue');

// Permissions (old)
const Permissions = () => import('@/pages/admin_portal/permissions/Permissions.vue');

// LightEdison Wiki
// const LightEdisonWiki = () => import('@/pages/lightedison_wiki/LightEdisonWiki.vue');

// Scheduling Component
const Scheduling = () => import('@/pages/scheduling/Scheduling.vue');

// Improvements Requests component
const ImprovementRequests = () => import('@/pages/improvement_requests/ImprovementRequests.vue');

// Checklists components
const ChecklistForm = () => import('@/pages/field_operations/checklists/checklist_form/ChecklistForm.vue');
const Checklists = () => import('@/pages/field_operations/checklists/Checklists.vue');

// Checklists components
const ConstructionEvaluationForm = () =>
  import('@/pages/field_operations/construction_evaluations/construction_evaluation_form/ConstructionEvaluationForm.vue');
const ConstructionEvaluationBuilder = () =>
  import('@/pages/field_operations/construction_evaluations/construction_evaluation_builder/ConstructionEvaluationBuilder.vue');
// const ConstructionEvaluations = () => import('@/pages/field_operations/construction_evaluations/ConstructionEvaluations.vue');

// Improvements Requests component
const ImprovementRequestForm = () => import('@/pages/improvement_requests/improvement_request_form/ImprovementRequestForm.vue');

// PV System Testing Reports
// const PhotovoltaicSystemTestReports = () =>
//   import('@/pages/field_operations/photovoltaic_system/testing/PhotovoltaicSystemTestReports.vue');
// const PhotovoltaicSystemCommissioningReports = () =>
//   import('@/pages/field_operations/photovoltaic_system/commissioning/PhotovoltaicSystemCommissioningReports.vue');
const PurchaseOrderServiceForm = () => import('@/components/procurement/PurchaseOrderServiceForm.vue');
// const ReparentSpaces = () => import('@/components/spaces/ReparentSpaces.vue');
// const ChangeOrderList = () => import('@/pages/change_orders/ChangeOrderList.vue');
// const ChangeOrderForm = () => import('@/pages/change_orders/ChangeOrderForm.vue');

// const DevelopmentTemplate = () => import('@/components/development/DevelopmentTemplate.vue');
// const DevelopmentRecord = () => import('@/components/development/DevelopmentRecord.vue');
// const FolderRequest = () => import('@/pages/operations/FolderRequest.vue');
// const MailGroups = () => import('@/components/MailGroups.vue');
const SolarProducts = () => import('@/components/products/viewProducts/SolarProducts.vue');
const PermissionDenied = () => import('@/components/generic/PermissionDenied.vue');
const CorpSchedule = () => import('@/pages/scheduling/CorpSchedule.vue');
// const PortfolioList = () => import('@/components/portfolios/PortfolioList.vue');
// const PortfolioForm = () => import('@/components/portfolios/PortfolioForm.vue');
// const JobProfitabilityByMonth = () => import('@/components/accounting/MonthlyPnL/JobProfitabilityByMonth.vue');
const CorporateExpense = () => import('@/components/accounting/CorporateExpense/CorporateExpense.vue');
const CorpScheduleList = () => import('@/pages/scheduling/CorpScheduleList.vue');
const SubmittalList = () => import('@/pages/submittals/SubmittalList.vue');
const SubmittalForm = () => import('@/pages/submittals/SubmittalForm.vue');
// const OrgChartPage = () => import('@/pages/orgchart/OrgChartPage.vue');
const OpportunityCentric = () => import('@/pages/opportunity/OpportunityCentric.vue');
const SchedulingTemplate = () => import('@/components/scheduling/SchedulingTemplate.vue');
const SchedulingTemplateList = () => import('@/components/scheduling/SchedulingTemplateList.vue');
const OpportunitySchedulePicker = () => import('@/components/scheduling/OpportunitySchedulePicker.vue');
const OpportunitySchedule = () => import('@/components/scheduling/OpportunitySchedule.vue');
// const PortfolioSchedule = () => import('@/components/scheduling/PortfolioSchedule.vue');
const HealthcareBenefits = () => import('@/pages/operations/human_resources/Healthcare/HealthcareBenefits.vue');
const ProjectFinanceTemplate = () => import('@/components/projectFinance/ProjectFinanceTemplate.vue');
const ProjectFinanceVersionPicker = () => import('@/components/projectFinance/ProjectFinanceVersionPicker.vue');
const ProjectFinanceEstimation = () => import('@/components/projectFinance/ProjectFinanceEstimation.vue');
const EmployeeHandbookReport = () => import('@/pages/operations/human_resources/employee_handbook/EmployeeHandbookReport.vue');
const GFormIframe = () => import('@/components/gformIframe/GFormIframe.vue');
const ProjectFinanceServices = () => import('@/components/projectFinance/ProjectFinanceServices.vue');
// const PortfolioFinance = () => import('@/components/projectFinance/PortfolioFinance.vue');
const ProjectFinanceGroupPicker = () => import('@/components/projectFinance/ProjectFinanceGroupPicker.vue');
const ProjectFinanceCompare = () => import('@/components/projectFinance/compareComponents/ProjectFinanceCompare.vue');
const ProjectFinanceGroupTotals = () => import('@/components/projectFinance/groupTotal/ProjectFinanceGroupTotals.vue');
const CorporateRates = () => import('@/components/accounting/CorporateRate/CorporateRates.vue');

const CorporateRoster = () => import('@/components/accounting/CorporateRate/CorporateRoster.vue');

const ManagerOverview = () => import('@/components/projectFinance/overviews/manager/ManagerOverview.vue');

// Position Agreement
const PositionAgreementForm = () => import('@/pages/operations/human_resources/position_agreements/PositionAgreementForm.vue');
const PositionAgreements = () => import('@/pages/operations/human_resources/position_agreements/PositionAgreements.vue');
const PayrollDeductionAuthorizationList = () => import('@/components/talent/payroll_deduction/PayrollDeductionAuthorizationList.vue');
const PayrollDeductionAuthorizationForm = () => import('@/components/talent/payroll_deduction/PayrollDeductionAuthorizationForm.vue');
const ApprenticeRequestTrackerList = () => import('@/components/ira/ApprenticeRequestTracker/ApprenticeRequestTrackerList.vue');
const ApprenticeRequestTrackerForm = () => import('@/components/ira/ApprenticeRequestTracker/ApprenticeRequestTrackerForm.vue');
const CertifiedPayrollList = () => import('@/components/certifiedPayroll/CertifiedPayrollList.vue');
const CertifiedPayrollRecord = () => import('@/components/certifiedPayroll/CertifiedPayrollRecord.vue');
const CertificateOfInsuranceList = () => import('@/components/compliance/certificateOfInsurance/CertificateOfInsuranceList.vue');
const CertificateOfInsuranceRecord = () => import('@/components/compliance/certificateOfInsurance/CertificateOfInsuranceRecord.vue');
// const SchemaList = () => import('@/components/genericForm/SchemaList.vue');
// const SchemaForm = () => import('@/components/genericForm/SchemaForm.vue');
// const TemplateSchemaList = () => import('@/components/genericForm/TemplateSchemaList.vue');
// const TemplateSchemaForm = () => import('@/components/genericForm/TemplateSchemaForm.vue');
// const SchemaBasedList = () => import('@/components/genericForm/SchemaBasedList.vue');
// const SchemaBasedForm = () => import('@/components/genericForm/SchemaBasedForm.vue');
// const TemplateSchemaBasedForm = () => import('@/components/genericForm/TemplateSchemaBasedForm.vue');
// Project Finance Export Templates
const PFExportTemplatesList = () => import('@/components/projectFinance/exportTemplates/PFExportTemplatesList.vue');
const PFExportTemplateForm = () => import('@/components/projectFinance/exportTemplates/PFExportTemplateForm.vue');
// const ProjectRecordForm = () => import('@/components/projectRecord/ProjectRecordForm.vue');
// const LightingReports = () => import('@/pages/field_operations/lightingReports.vue');
const PicklistList = () => import('@/components/picklists/PicklistList.vue');
const ActivityForm = () => import('@/components/tasks/ActivityForm.vue');

export const routes = [
  {
    path: '/',
    name: 'LoginComponent',
    component: LoginComponent,
    meta: {
      plainLayout: true,
      notRequiredAuth: true,
    },
  },
  // Improvement requests
  {
    path: '/continuous_improvement',
    name: 'ContinuousImprovement',
    component: ImprovementRequests,
    meta: {
      containerFluid: true,
    },
  },
  // Improvement request form
  {
    path: '/continuous_improvement/new',
    name: 'ContinuousImprovementForm',
    component: ImprovementRequestForm,
  },
  // Improvement request edit
  {
    path: '/continuous_improvement/:id',
    name: 'ContinuousImprovementEdit',
    component: ImprovementRequestForm,
  },
  // {
  //   path: '/quick_codes',
  //   name: 'QuickCode',
  //   component: QuickCode,
  // },
  // {
  //   path: '/quick_codes_archived',
  //   name: 'QuickCodeArchived',
  //   component: QuickCodeArchived,
  // },
  // {
  //   path: '/view_quick_code/:id',
  //   name: 'ViewQuickCode',
  //   component: ViewQuickCode,
  // },
  // {
  //   path: '/edit_quick_code/:id',
  //   name: 'EditQuickCode',
  //   component: EditQuickCode,
  // },
  // {
  //   path: '/duplicate_quick_code/:id',
  //   name: 'DuplicateQuickCode',
  //   component: DuplicateQuickCode,
  // },
  // {
  //   path: '/add_quick_code',
  //   name: 'AddQuickCode',
  //   component: AddQuickCode,
  // },
  {
    path: '/opportunities',
    name: 'Opportunities',
    component: Opportunities,
  },

  // ======= Contacts ====== //

  {
    path: '/contacts/new',
    name: 'ContactForm',
    component: ContactForm,
  },
  {
    path: '/contacts/:id',
    name: 'ContactEdit',
    component: ContactForm,
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
  },

  // ======= Accounting (Time Entries) ====== //
  {
    path: '/timesheets',
    name: 'Timesheets',
    component: Timesheets,
  },
  // {
  //   path: '/opportunity_time_dashboard',
  //   name: 'OpportunityTimeDashboard',
  //   component: OpportunityTimeDashboard,
  //   meta: {
  //     accessControl: () => {
  //       const permissionsService = ServiceContainer.get(PermissionsService);
  //       return permissionsService.canAccessOpportunityTimeDashboard();
  //     },
  //   },
  // },

  // ======= Accounting (Time Entries) ====== //
  {
    path: '/time_entries_expenses',
    name: 'TimeEntriesAndExpenses',
    component: TimeEntriesAndExpenses,
    meta: {
      containerFluid: true,
    },
  },

  // ======= Accounting (Labor Costing) ====== //
  // {
  //   path: '/labor-costing',
  //   name: 'LaborCosting',
  //   component: LaborCosting,
  //   meta: {
  //     containerFluid: true,
  //   },
  // },

  {
    path: '/corporateRates',
    name: 'CorporateRates',
    component: CorporateRates,
    meta: {
      containerFluid: true,
    },
  },
  {
    path: '/corporateRoster',
    name: 'CorporateRoster',
    component: CorporateRoster,
    meta: {
      containerFluid: true,
    },
  },
  {
    path: '/manager-overview',
    name: 'ManagerOverview',
    component: ManagerOverview,
    props: true,
    meta: {
      containerFluid: true,
    },
  },
  {
    path: '/RecentAPRecords',
    name: 'RecentAPRecordsList',
    component: APRecordsList,
    props: { recent: true },
    meta: {
      containerFluid: true,
    },
    children: [
      {
        path: 'embed/new',
        name: 'RecentAPL_NewAPRecord',
        component: APRecordForm,
        props: true,
        meta: {
          externalLink: () => ({
            name: 'NewAPRecord',
          }),
        },
      },
      {
        path: 'embed/:id',
        name: 'RecentAPL_EditAPRecord',
        component: APRecordForm,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'EditAPRecord',
            params: { id: route.params.id },
          }),
        },
      },
      {
        path: 'embed/clone/:id',
        name: 'RecentAPL_CloneAPRecord',
        component: APRecordForm,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'CloneAPRecord',
            params: { id: route.params.id },
          }),
        },
      },
      {
        path: 'purchaseOrders/:purchaseOrderId',
        name: 'RecentAPL_PurchaseOrderView',
        component: PurchaseOrderForm,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'PurchaseOrderView',
            params: { purchaseOrderId: route.params.purchaseOrderId },
          }),
        },
      },
      {
        path: 'purchaseOrders/s/:purchaseOrderId',
        name: 'RecentAPL_PurchaseOrderViewService',
        component: PurchaseOrderServiceForm,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'PurchaseOrderViewService',
            params: { purchaseOrderId: route.params.purchaseOrderId },
          }),
        },
      },
      {
        path: 'purchaseOrders/c/:purchaseOrderId',
        name: 'RecentAPL_PurchaseOrderViewOPEX',
        component: PurchaseOrderServiceForm,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'PurchaseOrderViewOPEX',
            params: { purchaseOrderId: route.params.purchaseOrderId },
          }),
        },
      },
    ],
  },
  {
    path: '/APRecords',
    name: 'APRecordsList',
    component: APRecordsList,
    meta: {
      containerFluid: true,
    },
    children: [
      {
        path: 'embed/new',
        name: 'APL_NewAPRecord',
        component: APRecordForm,
        props: true,
        meta: {
          externalLink: () => ({
            name: 'NewAPRecord',
          }),
        },
      },
      {
        path: 'embed/:id',
        name: 'APL_EditAPRecord',
        component: APRecordForm,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'EditAPRecord',
            params: { id: route.params.id },
          }),
        },
      },
      {
        path: 'embed/clone/:id',
        name: 'APL_CloneAPRecord',
        component: APRecordForm,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'CloneAPRecord',
            params: { id: route.params.id },
          }),
        },
      },
      {
        path: 'purchaseOrders/:purchaseOrderId',
        name: 'APL_PurchaseOrderView',
        component: PurchaseOrderForm,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'PurchaseOrderView',
            params: { purchaseOrderId: route.params.purchaseOrderId },
          }),
        },
      },
      {
        path: 'purchaseOrders/s/:purchaseOrderId',
        name: 'APL_PurchaseOrderViewService',
        component: PurchaseOrderServiceForm,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'PurchaseOrderViewService',
            params: { purchaseOrderId: route.params.purchaseOrderId },
          }),
        },
      },
      {
        path: 'purchaseOrders/c/:purchaseOrderId',
        name: 'APL_PurchaseOrderViewOPEX',
        component: PurchaseOrderServiceForm,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'PurchaseOrderViewOPEX',
            params: { purchaseOrderId: route.params.purchaseOrderId },
          }),
        },
      },
    ],
  },
  {
    path: '/APRecords/o/:opportunityID',
    name: 'APRecordsListByOpptyID',
    component: APRecordsList,
    props: true,
    meta: {
      containerFluid: true,
    },
  },
  {
    path: '/APRecords/new',
    name: 'NewAPRecord',
    component: APRecordForm,
    meta: {
      containerFluid: true,
    },
  },
  {
    path: '/APRecords/:id',
    name: 'EditAPRecord',
    component: APRecordForm,
    props: true,
    meta: {
      containerFluid: true,
    },
  },
  {
    path: '/APRecords/clone/:id',
    name: 'CloneAPRecord',
    component: APRecordForm,
    props: true,
    meta: {
      containerFluid: true,
    },
  },

  // ======= Checklist Views ====== //

  // Checklist Templates
  // {
  //   path: '/talent_checklists/templates',
  //   name: 'ChecklistTemplates',
  //   component: ChecklistTemplates,
  // },
  // {
  //   path: '/talent_checklists/templates/:checklistId',
  //   name: 'ChecklistTemplate',
  //   component: ChecklistTemplate,
  // },
  // {
  //   path: '/talent_checklists/templates/:checklistId/new',
  //   name: 'NewTemplateTask',
  //   component: ChecklistTemplate,
  // },
  // {
  //   path: '/talent_checklists/templates/:checklistId/:taskId',
  //   name: 'TemplateTask',
  //   component: ChecklistTemplate,
  // },

  // Checklist Records (for use later)
  // {
  //   path: '/talent_checklists/records',
  //   name: 'ChecklistRecords',
  //   component: ChecklistRecords,
  // },
  // {
  //   path: '/talent_checklists/records/:checklistId',
  //   name: 'ChecklistRecord',
  //   component: ChecklistRecord,
  // },
  // {
  //   path: '/talent_checklists/records/:checklistId/new',
  //   name: 'NewChecklistTask',
  //   component: ChecklistRecord,
  // },
  // {
  //   path: '/talent_checklists/records/:checklistId/:taskId',
  //   name: 'ChecklistTask',
  //   component: ChecklistRecord,
  // },

  // My tasks
  // {
  //   path: '/talent_checklists/my_tasks',
  //   name: 'MyChecklistTasks',
  //   component: MyChecklistTasks,
  // },
  // {
  //   path: '/talent_checklists/my_tasks/:taskId',
  //   name: 'MyChecklistTask',
  //   component: MyChecklistTasks,
  // },

  // ======= Accounting - Vacation Requests  ====== //
  // {
  //   path: '/vacation-requests',
  //   name: 'VacationRequests',
  //   component: VacationRequests,
  // },
  // {
  //   path: '/vacation-requests/new',
  //   name: 'VacationRequestNew',
  //   component: VacationRequestForm,
  // },
  // {
  //   path: '/vacation-requests/:id',
  //   name: 'VacationRequestEdit',
  //   component: VacationRequestForm,
  // },

  // Position Agreements
  {
    path: '/position-agreements/new',
    name: 'PositionAgreementNew',
    component: PositionAgreementForm,
  },
  {
    path: '/position-agreements/:id',
    name: 'PositionAgreementEdit',
    component: PositionAgreementForm,
  },
  {
    path: '/position-agreements',
    name: 'PositionAgreements',
    component: PositionAgreements,
  },

  // ======= Customer Relationships Mananagement - Accounts ====== //
  {
    path: '/accounts',
    name: 'Accounts',
    component: Accounts,
  },
  {
    path: '/accounts/new',
    name: 'AccountNew',
    component: AccountForm,
  },
  {
    path: '/accounts/:id',
    name: 'AccountEdit',
    component: AccountForm,
  },

  // ======= Customer Relationships Mananagement - Facilities ====== //
  {
    path: '/facilities',
    name: 'Facilities',
    component: Facilities,
  },
  {
    path: '/facilities/new',
    name: 'FacilityNew',
    component: FacilityForm,
  },
  {
    path: '/facilities/:id',
    name: 'FacilityEdit',
    component: FacilityForm,
  },

  // ======= Customer Relationships Mananagement - Contacts ====== //
  {
    path: '/opportunities-salesforce',
    name: 'SalesforceOpportunities',
    component: SalesforceOpportunities,
  },
  {
    path: '/opportunities-salesforce/new',
    name: 'SalesforceOpportunityNew',
    component: SalesforceOpportunityForm,
  },
  {
    path: '/opportunities-salesforce/:id',
    name: 'SalesforceOpportunityEdit',
    component: SalesforceOpportunityForm,
    meta: {
      opptySearchable: true,
    },
  },

  // ======= Customer Relationships Mananagement - Designs ====== //
  // {
  //   path: '/designs-salesforce',
  //   name: 'SalesforceDesigns',
  //   component: SalesforceDesigns,
  // },
  // {
  //   path: '/designs-salesforce/new',
  //   name: 'SalesforceDesignNew',
  //   component: SalesforceDesignForm,
  // },
  // {
  //   path: '/designs-salesforce/:id',
  //   name: 'SalesforceDesignEdit',
  //   component: SalesforceDesignForm,
  // },

  // ======= Customer Relationships Mananagement - Services ====== //
  // {
  //   path: '/services',
  //   name: 'Services',
  //   component: Services,
  // },
  // {
  //   path: '/services/new',
  //   name: 'ServiceNew',
  //   component: ServiceForm,
  // },
  // {
  //   path: '/services/:id',
  //   name: 'ServiceEdit',
  //   component: ServiceForm,
  // },

  // ======= Customer Relationships Mananagement - Project Engines ====== //
  // {
  //   path: '/project-engines',
  //   name: 'ProjectEngines',
  //   component: ProjectEngines,
  // },
  // {
  //   path: '/project-engines/new',
  //   name: 'ProjectEngineNew',
  //   component: ProjectEngineForm,
  // },
  // {
  //   path: '/project-engines/:id',
  //   name: 'ProjectEngineEdit',
  //   component: ProjectEngineForm,
  // },

  // ======= Customer Relationships Mananagement - Pre Contracting ====== //
  // {
  //   path: '/pre-contracting',
  //   name: 'PreContractingRecords',
  //   component: PreContractingRecords,
  // },
  // {
  //   path: '/pre-contracting/new',
  //   name: 'PreContractingNew',
  //   component: PreContractingForm,
  // },
  // {
  //   path: '/pre-contracting/:id',
  //   name: 'PreContractingEdit',
  //   component: PreContractingForm,
  // },

  // ======= Customer Relationships Mananagement - Contracted ====== //
  // {
  //   path: '/contracted',
  //   name: 'ContractedRecords',
  //   component: ContractedRecords,
  // },
  // {
  //   path: '/contracted/new',
  //   name: 'ContractedNew',
  //   component: ContractedForm,
  // },
  // {
  //   path: '/contracted/:id',
  //   name: 'ContractedEdit',
  //   component: ContractedForm,
  // },

  // Inspection Checklist
  {
    path: '/checklists',
    name: 'Checklists',
    component: Checklists,
  },
  {
    path: '/checklists/new',
    name: 'ChecklistNew',
    component: ChecklistForm,
  },
  {
    path: '/checklists/:id',
    name: 'ChecklistEdit',
    component: ChecklistForm,
  },

  // Construction Evaluations
  // {
  //   path: '/construction-evaluations',
  //   name: 'ConstructionEvaluations',
  //   component: ConstructionEvaluations,
  // },

  // Photovoltaic Test Reports
  // {
  //   path: '/pv-system-test-reports',
  //   name: 'PhotovoltaicSystemTestReports',
  //   component: PhotovoltaicSystemTestReports,
  // },

  // Photovoltaic Commissioning Reports
  // {
  //   path: '/pv-system-commissioning-reports',
  //   name: 'PhotovoltaicSystemCommissioningReports',
  //   component: PhotovoltaicSystemCommissioningReports,
  // },

  {
    path: '/construction-evaluations/builder/new',
    name: 'ConstructionEvaluationBuilderNew',
    component: ConstructionEvaluationBuilder,
    props: true,
  },
  {
    path: '/construction-evaluations/builder/:id',
    name: 'ConstructionEvaluationBuilderEdit',
    component: ConstructionEvaluationBuilder,
  },
  {
    path: '/construction-evaluations/new',
    name: 'ConstructionEvaluationNew',
    component: ConstructionEvaluationForm,
  },
  {
    path: '/construction-evaluations/:id',
    name: 'ConstructionEvaluationEdit',
    component: ConstructionEvaluationForm,
  },

  // ======= Customer Relationships Mananagement - Accounting ====== //
  // {
  //   path: '/accounting',
  //   name: 'AccountingRecords',
  //   component: AccountingRecords,
  // },
  // {
  //   path: '/accounting/new',
  //   name: 'AccountingNew',
  //   component: AccountingForm,
  // },
  // {
  //   path: '/accounting/:id',
  //   name: 'AccountingEdit',
  //   component: AccountingForm,
  // },

  // ======= Customer Relationships Mananagement - SOPs  ====== //
  {
    path: '/sops',
    name: 'SOPs',
    component: SOPs,
  },
  {
    path: '/sops/new',
    name: 'SOPNew',
    component: SOPForm,
  },
  {
    path: '/sops/:sopID',
    name: 'SOPEdit',
    component: SOPForm,
    props: true,
  },

  // ======= Customer Relationships Mananagement - Proposal Requests  ====== //
  // {
  //   path: '/proposal-requests',
  //   name: 'ProposalRequests',
  //   component: ProposalRequests,
  // },
  // {
  //   path: '/proposal-requests/new',
  //   name: 'ProposalRequestNew',
  //   component: ProposalRequestForm,
  // },
  // {
  //   path: '/proposal-requests/:id',
  //   name: 'ProposalRequestEdit',
  //   component: ProposalRequestForm,
  // },

  // ======= Customer Relationships Mananagement - Work Orders  ====== //
  // {
  //   path: '/work-orders',
  //   name: 'WorkOrders',
  //   component: WorkOrders,
  // },
  // {
  //   path: '/work-orders/new',
  //   name: 'WorkOrderNew',
  //   component: WorkOrderForm,
  // },
  // {
  //   path: '/work-orders/:id',
  //   name: 'WorkOrderEdit',
  //   component: WorkOrderForm,
  // },

  // ======= Customer Relationships Mananagement - Reports  ====== //
  // {
  //   path: '/reports',
  //   name: 'Reports',
  //   component: Reports,
  // },

  // ======= Customer Relationships Mananagement - Returns  ====== //
  // {
  //   path: '/returns',
  //   name: 'Returns',
  //   component: Returns,
  // },
  // {
  //   path: '/returns/new',
  //   name: 'returnNew',
  //   component: ReturnForm,
  // },
  // {
  //   path: '/returns/:id',
  //   name: 'ReturnEdit',
  //   component: ReturnForm,
  // },

  // ======= Scheduling ====== //

  {
    path: '/scheduling',
    name: 'Scheduling',
    component: Scheduling,
    meta: {
      containerFluid: true,
    },
  },

  // ======= Field Operations - Weekly Overview  ====== //
  // {
  //   path: '/weekly-overviews',
  //   name: 'WeeklyOverviews',
  //   component: WeeklyOverviews,
  // },
  // {
  //   path: '/weekly-overviews/new',
  //   name: 'WeeklyOverviewNew',
  //   component: WeeklyOverviewForm,
  // },
  // {
  //   path: '/weekly-overviews/:id',
  //   name: 'WeeklyOverviewEdit',
  //   component: WeeklyOverviewForm,
  // },

  // ======= Field Operations (Daily Report) ====== //
  {
    path: '/work_reports',
    name: 'WorkReports',
    component: WorkReports,
    meta: {
      allowInstaller: true,
    },
  },
  {
    path: '/work_reports/new',
    name: 'WorkReportForm',
    component: WorkReportForm,
    meta: {
      allowInstaller: true,
    },
  },
  {
    path: '/work_reports/:workReportID',
    name: 'WorkReport',
    component: WorkReport,
    meta: {
      allowInstaller: true,
    },
  },
  {
    path: '/work_reports/:workReportID/edit',
    name: 'WorkReportEdit',
    component: WorkReportForm,
    meta: {
      allowInstaller: true,
    },
  },

  // ======= Field Operations (Request For Information) ====== //

  {
    path: '/rfi_reports/new',
    name: 'RfiReportForm',
    component: ConstructionReportForm,
    props: {
      type: 'rfi',
    },
    meta: {
      allowInstaller: true,
    },
  },
  {
    path: '/rfi_reports/:id',
    name: 'RfiReportEdit',
    component: ConstructionReportForm,
    props: (route) => ({
      id: route.params.id,
      type: 'rfi',
    }),
    meta: {
      allowInstaller: true,
    },
  },

  // ======= Field Operations (Issues) ====== //

  {
    path: '/issues_reports/new',
    name: 'IssuesReportForm',
    component: ConstructionReportForm,
    props: {
      type: 'issue',
    },
    meta: {
      allowInstaller: true,
    },
  },
  {
    path: '/issues_reports/:id',
    name: 'IssuesReportEdit',
    component: ConstructionReportForm,
    props: (route) => ({
      id: route.params.id,
      type: 'issue',
    }),
    meta: {
      allowInstaller: true,
    },
  },

  // ======= Field Operations (Punch List) ====== //
  {
    path: '/punch_list_reports/new',
    name: 'PunchListReportForm',
    component: ConstructionReportForm,
    meta: {
      allowInstaller: true,
    },
    props: {
      type: 'punchList',
    },
  },
  {
    path: '/punch_list_reports/:id',
    name: 'PunchListReportEdit',
    component: ConstructionReportForm,
    meta: {
      allowInstaller: true,
    },
    props: (route) => ({
      id: route.params.id,
      type: 'punchList',
    }),
  },

  // {
  //   path: '/lighting_reports',
  //   name: 'LightingReports',
  //   component: LightingReports,
  //   meta: {
  //     allowInstaller: true,
  //   },
  // },

  // =========== Field Operations (Space Reporting) ============ //
  {
    path: '/submitted_reports',
    name: 'ConstructionReporting',
    component: ConstructionReporting,
    meta: {
      allowInstaller: true,
    },
  },
  {
    path: '/submitted_reports/:opportunityID',
    name: 'ConstructionReportingByOpportunityID',
    component: ConstructionReporting,
    props: true,
    meta: {
      allowInstaller: true,
    },
  },

  // =========== Field Operations (Vehicles) ============ //
  // {
  //   path: '/vehicles',
  //   name: 'Vehicles',
  //   component: Vehicles,
  // },
  // {
  //   path: '/vehicles/new',
  //   name: 'VehicleNew',
  //   component: VehicleForm,
  // },
  // {
  //   path: '/vehicles/:id',
  //   name: 'VehicleEdit',
  //   component: VehicleForm,
  // },

  // =========== Field Operations (Equipments) ============ //
  // {
  //   path: '/equipments',
  //   name: 'Equipments',
  //   component: Equipments,
  // },
  // {
  //   path: '/equipments/new',
  //   name: 'NewEquipment',
  //   component: EquipmentForm,
  // },
  // {
  //   path: '/equipments/:id',
  //   name: 'EquipmentEdit',
  //   component: EquipmentForm,
  // },

  // =========== Field Operations (Vehicles) ============ //
  // {
  //   path: '/equipment-inspections',
  //   name: 'EquipmentInspections',
  //   component: EquipmentInspections,
  // },
  // {
  //   path: '/equipment-inspections/new',
  //   name: 'NewEquipmentInspection',
  //   component: EquipmentInspectionForm,
  // },
  // {
  //   path: '/equipment-inspections/:id',
  //   name: 'EquipmentInspectionRecord',
  //   component: EquipmentInspectionForm,
  // },

  // =========== Operations Items - Quarterly Evaluation ============= //
  {
    path: '/quarterly_evaluations',
    name: 'QuarterlyEvaluations',
    component: QuarterlyEvaluations,
  },
  {
    path: '/quarterly_evaluations/new',
    name: 'QuarterlyEvaluationNew',
    component: QuarterlyEvaluationForm,
  },
  {
    path: '/quarterly_evaluations/quarterly_employee_report',
    name: 'QuarterlyEmployeeReport',
    component: QuarterlyEmployeeReport,
    meta: {
      containerFluid: true,
      fillHeight: true,
    },
  },
  {
    path: '/quarterly_evaluations/:id',
    name: 'QuarterlyEvaluationEdit',
    component: QuarterlyEvaluationForm,
  },
  // =========== Operations Items - Employee Handbook ============= //
  {
    path: '/employee_handbook',
    name: 'EmployeeHandbook',
    component: EmployeeHandbook,
  },
  {
    path: '/employeeHandbookReport',
    name: 'EmployeeHandbookReport',
    component: EmployeeHandbookReport,
  },
  // =========== Operations Items - Healthcare Benefits ============= //
  {
    path: '/healthcareBenefits/:year/:itemID',
    name: 'HealthcareBenefits',
    component: HealthcareBenefits,
    props: true,
  },

  // =========== Operations Items - Department Of Labor Notifications ============= //
  {
    path: '/department_of_labor_notifications',
    name: 'DepartmentOfLaborNotifications',
    component: DepartmentOfLaborNotifications,
  },

  // =========== OSHA 300 Form ============= //
  {
    path: '/osha_form',
    name: 'OshaForm',
    component: OshaForm,
  },

  // =========== Operations Items - Termination Form / Records ============= //
  {
    path: '/termination_records/new',
    name: 'TerminationForm',
    component: TerminationForm,
  },
  {
    path: '/termination_records',
    name: 'TerminationRecords',
    component: TerminationRecords,
  },
  {
    path: '/termination_records/:id',
    name: 'TerminationItem',
    component: TerminationForm,
  },

  // =========== Operations Items - Flight Requests ============= //
  // {
  //   path: '/flight_requests',
  //   name: 'FlightRequests',
  //   component: FlightRequests,
  // },
  // {
  //   path: '/flight_requests/new',
  //   name: 'FlightRequestForm',
  //   component: FlightRequestForm,
  // },
  // {
  //   path: '/flight_requests/:id',
  //   name: 'FlightRequestUpdate',
  //   component: FlightRequestForm,
  // },

  // =========== Operations Items - Tool Log ============= //
  // {
  //   path: '/tool_logs/new',
  //   name: 'ToolLogForm',
  //   component: ToolLogForm,
  // },
  // {
  //   path: '/tool_logs',
  //   name: 'ToolLogs',
  //   component: ToolLogs,
  // },
  // {
  //   path: '/tool_logs/:id',
  //   name: 'ToolLogUpdate',
  //   component: ToolLogForm,
  // },

  // =========== Operations Items - LightEdison wiki ============= //
  // {
  //   path: '/lightedison_wiki',
  //   name: 'LightEdisonWiki',
  //   component: LightEdisonWiki,
  // },
  // =========== Operations Items - Employee Apparel ============= //
  // {
  //   path: '/employee-apparel',
  //   name: 'EmployeeApparel',
  //   component: EmployeeApparel,
  // },
  // ==================================================== //

  {
    path: '/spaces/:id',
    name: 'Spaces',
    component: Spaces,
  },
  {
    path: '/view_space/:spaceId/:opportunityId',
    name: 'ViewSpace',
    component: ViewSpace,
  },
  {
    path: '/view_space_with_qc/:spaceId/:opportunityId',
    name: 'ViewSpaceWithQC',
    component: ViewSpaceWithQC,
  },
  {
    path: '/add_space/:opportunityId',
    name: 'AddSpace',
    component: AddSpace,
  },
  {
    path: '/add_space_qc_selection/:opportunityId',
    name: 'AddSpaceQCSelection',
    component: AddSpaceQCSelection,
  },
  {
    path: '/add_space_selected_qc/:opportunityId/:qcId',
    name: 'AddSpaceSelectedQC',
    component: AddSpaceSelectedQC,
  },
  {
    path: '/edit_space/:opportunityId/:spaceId',
    name: 'EditSpace',
    component: EditSpace,
  },
  {
    path: '/duplicate_space/:opportunityId/:spaceId/:options',
    name: 'DuplicateSpaceManual',
    component: DuplicateSpaceManual,
  },
  {
    path: '/duplicate_space_quick/:opportunityId/:spaceId/:options',
    name: 'DuplicateSpaceQuick',
    component: DuplicateSpaceQuick,
  },
  {
    path: '/edit_space_quick/:opportunityId/:spaceId',
    name: 'EditSpaceQuick',
    component: EditSpaceQuick,
  },
  {
    path: '/coming_soon',
    name: 'comingSoon',
    component: comingSoon,
  },
  {
    path: '/home',
    name: 'home',
    component: home,
  },

  // -------- Admin Portal - Roles ---------- //
  {
    path: '/roles',
    name: 'Roles',
    component: Roles,
  },
  {
    path: '/roles/new',
    name: 'RoleNew',
    component: RoleForm,
  },
  {
    path: '/roles/:id',
    name: 'RoleView',
    component: RoleForm,
  },

  // -------- Admin Portal - Users ------- //
  {
    path: '/users',
    name: 'Users',
    component: AllUsers,
  },
  {
    path: '/users/new',
    name: 'New User',
    component: UserForm,
  },
  {
    path: '/users/:id',
    name: 'UserEdit',
    component: UserForm,
  },
  // -------- Admin Portal - Users ---------- //
  {
    path: '/permissions',
    name: 'permissions',
    component: Permissions,
  },
  // {
  //   path: '/pm_verification',
  //   name: 'PM Verification',
  //   component: PMVerification,
  // },
  {
    path: '/control_products',
    name: 'ControlProducts',
    component: ControlProducts,
  },
  {
    path: '/accessory_products',
    name: 'AccessoryProducts',
    component: AccessoryProducts,
  },
  {
    path: '/solar_products',
    name: 'SolarProducts',
    component: SolarProducts,
  },
  {
    path: '/primary_products',
    name: 'PrimaryProducts',
    component: PrimaryProducts,
  },
  {
    path: '/view_product/:productId',
    name: 'ViewProduct',
    component: ViewProduct,
  },
  {
    path: '/create_primaryProduct',
    name: 'CreatePrimaryProduct',
    component: CreatePrimaryProduct,
  },
  {
    path: '/create_controlProduct',
    name: 'CreateControlProduct',
    component: CreateControlProduct,
  },
  {
    path: '/create_accessoryProduct',
    name: 'CreateAccessoryProduct',
    component: CreateAccessoryProduct,
  },
  {
    path: '/create_solarProduct',
    name: 'CreateSolarProduct',
    component: CreateSolarProduct,
  },
  {
    path: '/edit_accessoryProduct/:id',
    name: 'EditAccessoryProduct',
    component: EditAccessoryProduct,
  },
  {
    path: '/edit_SolarProduct/:id',
    name: 'EditSolarProduct',
    component: CreateSolarProduct,
  },
  {
    path: '/edit_controlProduct/:id',
    name: 'EditControlProduct',
    component: EditControlProduct,
  },
  {
    path: '/edit_primaryProduct/:id',
    name: 'EditPrimaryProduct',
    component: EditPrimaryProduct,
  },
  {
    path: '/duplicate_accessoryProduct/:id',
    name: 'DuplicateAccessoryProduct',
    component: DuplicateAccessoryProduct,
  },
  {
    path: '/duplicate_solarProduct/:id',
    name: 'DuplicateSolarProduct',
    component: CreateSolarProduct,
  },
  {
    path: '/duplicate_controlProduct/:id',
    name: 'DuplicateControlProduct',
    component: DuplicateControlProduct,
  },
  {
    path: '/duplicate_primaryProduct/:id',
    name: 'DuplicatePrimaryProduct',
    component: DuplicatePrimaryProduct,
  },
  // {
  //   path: '/create_design',
  //   name: 'CreateDesign',
  //   component: CreateDesign,
  // },
  // {
  //   path: '/design/opportunities',
  //   name: 'DesignOpportunities',
  //   component: DesignOpportunities,
  // },
  // {
  //   path: '/design/design_spaces/:id/:spaceId',
  //   name: 'DesignSpaces',
  //   component: DesignSpaces,
  // },
  // {
  //   path: '/design/new_design/:spaceId/:opportunityId',
  //   name: 'NewDesign',
  //   component: NewDesign,
  // },
  // {
  //   path: '/design/design_accessory_product/:opportunityId/:selectedProduct/:existingIndex/:designIndex',
  //   name: 'DesignAccessoryProduct',
  //   component: DesignAccessoryProduct,
  // },
  // {
  //   path: '/design/design_control_product/:opportunityId/:selectedProduct/:existingIndex/:designIndex',
  //   name: 'DesignControlProduct',
  //   component: DesignControlProduct,
  // },
  // {
  //   path: '/design/design_primary_product/:opportunityId/:selectedProduct/:existingIndex/:designIndex',
  //   name: 'DesignPrimaryProduct',
  //   component: DesignPrimaryProduct,
  // },
  // {
  //   path: '/design/view_designs/:spaceId',
  //   name: 'ViewDesigns',
  //   component: ViewDesigns,
  // },
  // {
  //   path: '/design/view_selected_design/:spaceId/:designId',
  //   name: 'ViewSelectedDesign',
  //   component: ViewSelectedDesign,
  // },
  // {
  //   path: '/design/edit_selected_design/:spaceId/:designId',
  //   name: 'EditSelectedDesign',
  //   component: EditSelectedDesign,
  // },
  // {
  //   path: '/design/duplicate_selected_design/:spaceId/:designId',
  //   name: 'DuplicateSelectedDesign',
  //   component: DuplicateSelectedDesign,
  // },
  {
    path: '/tempPrimaryProducts',
    name: 'tempPrimaryProducts',
    component: tempPrimaryProducts,
  },
  // {
  //   path: '/designs/designBox/:opportunityId',
  //   name: 'designBox',
  //   component: DesignBox,
  // },
  // {
  //   path: '/designs/ecms/:opportunityId',
  //   name: 'ECMs',
  //   component: ECMs,
  //   props: true,
  //   meta: {
  //     opptySearchable: true,
  //   },
  // },
  // {
  //   path: '/designs/build_ecms/:opportunityId',
  //   name: 'BuildECMs',
  //   component: BuildECMs,
  // },
  {
    path: '/forgot_password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      plainLayout: true,
      notRequiredAuth: true,
    },
  },
  {
    path: '/spaces/:id/missing_data',
    name: 'MissingData',
    component: MissingData,
  },
  {
    path: '/installations/:opportunityId',
    name: 'Installations',
    component: Installations,
  },
  // {
  //   path: '/installationMap/:opportunityId',
  //   name: 'InstallationMap',
  //   component: InstallationMap,
  //   props: true,
  //   meta: {
  //     containerFluid: true,
  //     opptySearchable: true,
  //   },
  // },
  {
    path: '/buildings/:opportunityId',
    name: 'BuildingList',
    component: BuildingList,
    props: true,
    meta: {
      opptySearchable: true,
    },
  },
  {
    path: '/buildings/create/:opportunityID',
    name: 'BuildingFormCreate',
    component: BuildingForm,
    props: true,
    meta: {
      opptySearchable: true,
    },
  },
  {
    path: '/buildings/view/:buildingID',
    name: 'BuildingFormView',
    component: BuildingForm,
    props: true,
  },
  {
    path: '/buildings/edit/:buildingID',
    name: 'BuildingFormUpdate',
    component: BuildingForm,
    props: (route) => ({
      buildingID: route.params.buildingID,
      forceEdit: true,
    }),
  },
  {
    path: '/buildings/clone/:buildingID',
    name: 'BuildingFormClone',
    component: BuildingForm,
  },
  // {
  //   path: '/designs/:opportunityId',
  //   name: 'Designs',
  //   component: Designs,
  // },
  {
    path: '/product_summary/:opportunityID',
    name: 'ProductSummary',
    component: ProductSummary,
    props: true,
    meta: {
      opptySearchable: true,
    },
  },

  // change orders
  // {
  //   path: '/change_orders',
  //   name: 'ChangeOrderList',
  //   component: ChangeOrderList,
  // },
  // {
  //   path: '/change_orders/new',
  //   name: 'ChangeOrderNew',
  //   component: ChangeOrderForm,
  // },
  // {
  //   path: '/change_orders/:changeOrderId',
  //   name: 'ChangeOrderView',
  //   component: ChangeOrderForm,
  //   props: true,
  // },

  // submittals
  {
    path: '/submittals/:opportunityId',
    name: 'SubmittalList',
    component: SubmittalList,
    props: true,
    meta: {
      containerFluid: true,
      fillHeight: true,
      opptySearchable: true,
    },
  },
  {
    path: '/submittals/:opportunityId/new',
    name: 'SubmittalNew',
    component: SubmittalForm,
    props: true,
  },
  {
    path: '/submittals/:opportunityId/clone/:submittalId',
    name: 'SubmittalClone',
    component: SubmittalForm,
    props: true,
  },
  {
    path: '/submittals/:opportunityId/:submittalId',
    name: 'SubmittalView',
    component: SubmittalForm,
    props: true,
  },

  // procurement
  {
    path: '/gform/:itemID',
    name: 'EmbeddedGForm',
    component: GFormIframe,
    props: true,
  },
  // purchase orders
  {
    path: '/purchaseOrders/create',
    name: 'PurchaseOrderCreate',
    component: PurchaseOrderForm,
    props: (route) => ({
      purchaseOrderData: route.params.purchaseOrderData,
    }),
  },
  {
    path: '/purchaseOrders/clone/:purchaseOrderId',
    name: 'ClonePO',
    component: PurchaseOrderForm,
    props: true,
  },
  {
    path: '/purchaseOrders/createCustom/:opportunityID',
    name: 'PurchaseOrderCreateCustom',
    component: PurchaseOrderForm,
    props: true,
  },
  {
    path: '/purchaseOrders/createOPEX',
    name: 'PurchaseOrderCreateOPEX',
    component: PurchaseOrderServiceForm,
    props: true,
  },
  {
    path: '/purchaseOrders/viewOPEX/:purchaseOrderId',
    name: 'PurchaseOrderViewOPEX',
    component: PurchaseOrderServiceForm,
    props: true,
  },
  {
    path: '/purchaseOrders/createService/:opportunityID',
    name: 'PurchaseOrderCreateService',
    component: PurchaseOrderServiceForm,
    props: true,
  },
  {
    path: '/purchaseOrders/viewService/:purchaseOrderId',
    name: 'PurchaseOrderViewService',
    component: PurchaseOrderServiceForm,
    props: true,
  },
  {
    path: '/purchaseOrders/:purchaseOrderId',
    name: 'PurchaseOrderView',
    component: PurchaseOrderForm,
    props: true,
  },
  {
    path: '/purchaseOrders',
    name: 'PurchaseOrderList',
    component: PurchaseOrders,
  },
  {
    path: '/purchaseOrders/byOppty/:opportunityId',
    name: 'PurchaseOrderListByOppty',
    component: PurchaseOrders,
    props: true,
  },
  {
    path: '/shipmentFromPO/:purchaseOrderId',
    name: 'ShipmentCreate',
    component: POShipmentForm,
    props: true,
  },
  {
    path: '/shipments/:shipmentId',
    name: 'ShipmentView',
    component: POShipmentForm,
    props: true,
  },
  {
    path: '/shipments',
    name: 'ShipmentList',
    component: Shipments,
  },
  //
  // {
  //   path: '/jobProfitability/:opportunityID',
  //   name: 'JobProfitability',
  //   component: Profitability,
  //   props: true,
  //   meta: {
  //     opptySearchable: true,
  //   },
  // },
  // {
  //   path: '/jobProfitabilityByMonth/:opportunityID',
  //   name: 'JobProfitabilityByMonth',
  //   component: JobProfitabilityByMonth,
  //   props: true,
  //   meta: {
  //     opptySearchable: true,
  //   },
  // },
  // {
  //   path: '/jobProfitabilityByMonth/p/:portfolioID',
  //   name: 'PortfolioProfitabilityByMonth',
  //   component: JobProfitabilityByMonth,
  //   props: true,
  // },
  //
  {
    path: '/corporateExpense',
    name: 'CorporateExpense',
    component: CorporateExpense,
  },
  // Development
  // {
  //   path: '/development/template',
  //   name: 'DevelopmentTemplate',
  //   component: DevelopmentTemplate,
  // },
  // {
  //   path: '/development/:opportunityID',
  //   name: 'DevelopmentRecord',
  //   component: DevelopmentRecord,
  //   props: true,
  //   meta: {
  //     containerFluid: true,
  //     opptySearchable: true,
  //   },
  // },
  // Portfolios
  // {
  //   path: '/portfolio/list',
  //   name: 'PortfolioList',
  //   component: PortfolioList,
  // },
  // {
  //   path: '/portfolio/add',
  //   name: 'PortfolioAdd',
  //   component: PortfolioForm,
  // },
  // {
  //   path: '/portfolio/:portfolioID',
  //   name: 'PortfolioEdit',
  //   component: PortfolioForm,
  //   props: true,
  // },
  // mail groups
  // {
  //   path: '/mailgroups',
  //   name: 'MailGroups',
  //   component: MailGroups,
  // },
  // mail groups
  {
    path: '/permissionDenied',
    name: 'PermissionDenied',
    component: PermissionDenied,
  },
  // Project Finance
  {
    path: '/projectFinance/template',
    name: 'ProjectFinanceTemplate',
    component: ProjectFinanceTemplate,
  },
  {
    path: '/projectFinance/:opportunityID',
    name: 'ProjectFinancePicker',
    component: ProjectFinanceGroupPicker,
    props: true,
    meta: {
      opptySearchable: true,
    },
  },
  {
    path: '/projectFinance/:opportunityID/totals',
    name: 'ProjectFinanceTotalView',
    component: ProjectFinanceGroupTotals,
    props: true,
    meta: {
      opptySearchable: true,
      containerFluid: true,
    },
  },
  {
    path: '/projectFinance/:opportunityID/services',
    name: 'ProjectFinanceServices',
    component: ProjectFinanceServices,
    props: true,
    meta: {
      containerFluid: true,
      fillHeight: true,
      opptySearchable: true,
    },
    children: [
      // purchase orders
      {
        path: 'purchaseOrders',
        name: 'PFS_PurchaseOrderList',
        component: PurchaseOrders,
        props: (route) => ({
          opportunityId: route.params.opportunityID,
          showNav: false,
        }),
        meta: {
          externalLink: (route) => ({
            name: 'PurchaseOrderListByOppty',
            params: { opportunityId: route.params.opportunityID },
          }),
        },
      },
      {
        path: 'purchaseOrders/:purchaseOrderId',
        name: 'PFS_PurchaseOrderView',
        component: PurchaseOrderForm,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'PurchaseOrderView',
            params: { purchaseOrderId: route.params.purchaseOrderId },
          }),
        },
      },
      {
        path: 'purchaseOrders/s/:purchaseOrderId',
        name: 'PFS_PurchaseOrderViewService',
        component: PurchaseOrderServiceForm,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'PurchaseOrderViewService',
            params: { purchaseOrderId: route.params.purchaseOrderId },
          }),
        },
      },
      //APRecords
      {
        path: 'APRecords',
        name: 'PFS_APRecords',
        component: APRecordsList,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'APRecordsListByOpptyID',
            params: { opportunityID: route.params.opportunityID },
          }),
        },
      },
      {
        path: 'APRecords/new',
        name: 'PFS_NewAPRecord',
        component: APRecordForm,
        props: true,
        meta: {
          externalLink: () => ({
            name: 'NewAPRecord',
          }),
        },
      },
      {
        path: 'APRecords/:id',
        name: 'PFS_EditAPRecord',
        component: APRecordForm,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'EditAPRecord',
            params: { id: route.params.id },
          }),
        },
      },
      {
        path: 'APRecords/clone/:id',
        name: 'PFS_CloneAPRecord',
        component: APRecordForm,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'CloneAPRecord',
            params: { id: route.params.id },
          }),
        },
      },
    ],
  },
  {
    path: '/projectFinance/:opportunityID/:group',
    name: 'ProjectFinanceVersionPicker',
    component: ProjectFinanceVersionPicker,
    props: true,
  },
  {
    path: '/projectFinance/:opportunityID/:group/compare',
    name: 'ProjectFinanceCompare',
    component: ProjectFinanceCompare,
    props: true,
    meta: {
      containerFluid: true,
    },
  },
  {
    path: '/projectFinance/:opportunityID/:group/:version',
    name: 'ProjectFinance',
    component: ProjectFinanceEstimation,
    props: true,
    meta: {
      containerFluid: true,
    },
  },
  // Project Finance Export Templates
  {
    name: 'PFExportTemplatesList',
    path: '/pfExportTemplates',
    component: PFExportTemplatesList,
    meta: {
      containerFluid: true,
    },
  },
  {
    name: 'PFExportTemplateCreate',
    path: '/pfExportTemplates/new',
    component: PFExportTemplateForm,
    meta: {
      containerFluid: true,
    },
  },
  {
    name: 'PFExportTemplateEdit',
    path: '/pfExportTemplates/:id',
    component: PFExportTemplateForm,
    props: true,
    meta: {
      containerFluid: true,
    },
  },
  // {
  //   name: 'ProjectRecord',
  //   path: '/projectRecord/:opportunityId/:opportunityName',
  //   component: ProjectRecordForm,
  //   props: true,
  //   meta: {
  //     containerFluid: true,
  //   },
  // },
  // Portfolio Finance
  // {
  //   path: '/PortfolioFinance/:portfolioID',
  //   name: 'PortfolioFinance',
  //   component: PortfolioFinance,
  //   props: true,
  //   meta: {
  //     containerFluid: true,
  //     fillHeight: true,
  //   },
  // },
  {
    path: '/corpSchedule',
    name: 'CorpScheduleList',
    component: CorpScheduleList,
    meta: {
      containerFluid: true,
    },
  },
  {
    path: '/corpSchedule/:scheduleID',
    name: 'CorpScheduleGantt',
    component: CorpSchedule,
    props: true,
    meta: {
      containerFluid: true,
      fillHeight: true,
    },
  },
  {
    path: '/schedulingTemplate',
    name: 'SchedulingTemplateList',
    component: SchedulingTemplateList,
    meta: {
      containerFluid: true,
      fillHeight: true,
    },
  },
  {
    path: '/schedulingTemplate/:templateID',
    name: 'SchedulingTemplate',
    component: SchedulingTemplate,
    props: true,
    meta: {
      containerFluid: true,
      fillHeight: true,
    },
  },
  {
    path: '/scheduling/:opportunityID',
    name: 'OpportunitySchedule',
    component: OpportunitySchedulePicker,
    props: true,
    meta: {
      containerFluid: true,
      fillHeight: true,
      opptySearchable: true,
    },
  },
  {
    path: '/scheduling/:opportunityID/:version',
    name: 'OpportunityScheduleVersion',
    component: OpportunitySchedule,
    props: true,
    meta: {
      containerFluid: true,
      fillHeight: true,
    },
  },
  // {
  //   path: '/portfolioSchedule/:portfolioID',
  //   name: 'PortfolioSchedule',
  //   component: PortfolioSchedule,
  //   props: true,
  //   meta: {
  //     containerFluid: true,
  //   },
  // },
  // {
  //   path: '/orgchart',
  //   name: 'OrgChart',
  //   component: OrgChartPage,
  //   meta: {
  //     containerFluid: true,
  //     fillHeight: true,
  //   },
  // },
  {
    path: '/opportunity/:opportunityID',
    name: 'OpportunityCentric',
    component: OpportunityCentric,
    props: true,
    meta: {
      containerFluid: true,
      fillHeight: true,
      opptySearchable: true,
    },
    children: [
      //buildings
      {
        path: 'buildings/create',
        name: 'Opportunity_BuildingFormCreate',
        component: BuildingForm,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'BuildingFormCreate',
            params: { opportunityID: route.params.opportunityID },
          }),
        },
      },
      {
        path: 'buildings/view/:buildingID',
        name: 'Opportunity_BuildingFormView',
        component: BuildingForm,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'BuildingFormView',
            params: { buildingID: route.params.buildingID },
          }),
        },
      },
      {
        path: 'buildings/edit/:buildingID',
        name: 'Opportunity_BuildingFormUpdate',
        component: BuildingForm,
        props: (route) => ({
          buildingID: route.params.buildingID,
          forceEdit: true,
        }),
        meta: {
          externalLink: (route) => ({
            name: 'BuildingFormUpdate',
            params: { buildingID: route.params.buildingID },
          }),
        },
      },
      {
        path: 'buildings/clone/:buildingID',
        name: 'Opportunity_BuildingFormClone',
        component: BuildingForm,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'BuildingFormClone',
            params: { buildingID: route.params.buildingID },
          }),
        },
      },
      // change orders
      // {
      //   path: 'change_orders/new',
      //   name: 'Opportunity_ChangeOrderNew',
      //   component: ChangeOrderForm,
      //   meta: {
      //     externalLink: { name: 'ChangeOrderNew' },
      //   },
      // },
      // {
      //   path: 'change_orders/:changeOrderId',
      //   name: 'Opportunity_ChangeOrderView',
      //   component: ChangeOrderForm,
      //   props: true,
      //   meta: {
      //     externalLink: (route) => ({
      //       name: 'ChangeOrderView',
      //       params: { changeOrderId: route.params.changeOrderId },
      //     }),
      //   },
      // },
      // product summary
      {
        path: 'purchaseOrders/createService',
        name: 'Opportunity_PurchaseOrderCreateService',
        component: PurchaseOrderServiceForm,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'PurchaseOrderCreateService',
            params: { opportunityID: route.params.opportunityID },
          }),
        },
      },
      {
        path: 'purchaseOrders/createCustom',
        name: 'Opportunity_PurchaseOrderCreateCustom',
        component: PurchaseOrderForm,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'PurchaseOrderCreateCustom',
            params: { opportunityID: route.params.opportunityID },
          }),
        },
      },
      {
        path: 'purchaseOrders/create',
        name: 'Opportunity_PurchaseOrderCreate',
        component: PurchaseOrderForm,
        props: (route) => ({
          purchaseOrderData: route.params.purchaseOrderData,
        }),
      },
      // purchase orders
      {
        path: 'purchaseOrders/:purchaseOrderId',
        name: 'Opportunity_PurchaseOrderView',
        component: PurchaseOrderForm,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'PurchaseOrderView',
            params: { purchaseOrderId: route.params.purchaseOrderId },
          }),
        },
      },
      {
        path: 'purchaseOrders/viewService/:purchaseOrderId',
        name: 'Opportunity_PurchaseOrderViewService',
        component: PurchaseOrderServiceForm,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'PurchaseOrderViewService',
            params: { purchaseOrderId: route.params.purchaseOrderId },
          }),
        },
      },
      // shipments
      {
        path: 'shipments/:shipmentId',
        name: 'Opportunity_ShipmentView',
        component: POShipmentForm,
        props: true,
        meta: {
          externalLink: (route) => ({
            name: 'ShipmentView',
            params: { shipmentId: route.params.shipmentId },
          }),
        },
      },
      // daily reports
      {
        path: 'work_reports/new',
        name: 'Opportunity_WorkReportForm',
        component: WorkReportForm,
        meta: {
          externalLink: 'WorkReportForm',
        },
      },
      {
        path: 'work_reports/:workReportID/edit',
        name: 'Opportunity_WorkReportEdit',
        component: WorkReportForm,
        meta: {
          externalLink: (route) => ({
            name: 'WorkReportEdit',
            params: { workReportID: route.params.workReportID },
          }),
        },
      },
    ],
  },
  // {
  //   path: '/reparentSpaces',
  //   name: 'ReparentSpaces',
  //   component: ReparentSpaces,
  //   meta: {
  //     accessControl: () => {
  //       const permissionsService = ServiceContainer.get(PermissionsService);
  //       return permissionsService.canAccessReparentSpaces();
  //     },
  //   },
  // },
  // {
  //   path: '/projectShareFolder',
  //   name: 'ProjectShareFolder',
  //   component: FolderRequest,
  //   meta: {
  //     containerFluid: true,
  //   },
  // },
  // Payroll Deduction Authorization
  {
    name: 'PayrollDeductionAuthorizationList',
    path: '/payrollDeductionAuthorization',
    component: PayrollDeductionAuthorizationList,
    meta: {
      containerFluid: true,
    },
  },
  {
    name: 'PayrollDeductionAuthorizationCreate',
    path: '/payrollDeductionAuthorization/new',
    component: PayrollDeductionAuthorizationForm,
  },
  {
    name: 'PayrollDeductionAuthorizationEdit',
    path: '/payrollDeductionAuthorization/:id',
    component: PayrollDeductionAuthorizationForm,
    props: true,
  },
  // RAP Certified Apprentice Request Tracker
  {
    name: 'ApprenticeRequestTrackerList',
    path: '/rapCARTs',
    component: ApprenticeRequestTrackerList,
    meta: {
      containerFluid: true,
    },
  },
  {
    name: 'ApprenticeRequestTrackerAdd',
    path: '/rapCARTs/new',
    component: ApprenticeRequestTrackerForm,
  },
  {
    name: 'ApprenticeRequestTrackerEdit',
    path: '/rapCARTs/:id',
    component: ApprenticeRequestTrackerForm,
    props: true,
  },
  // Certified Payroll
  {
    name: 'CertifiedPayrollList',
    path: '/certifiedPayrolls',
    component: CertifiedPayrollList,
    meta: {
      containerFluid: true,
    },
  },
  {
    name: 'CertifiedPayrollAdd',
    path: '/certifiedPayrolls/new',
    component: CertifiedPayrollRecord,
  },
  {
    name: 'CertifiedPayrollEdit',
    path: '/certifiedPayrolls/:id',
    component: CertifiedPayrollRecord,
    props: true,
  },
  //Certificates of Insurance
  {
    name: 'CertificateOfInsuranceList',
    path: '/certificatesOfInsurance',
    component: CertificateOfInsuranceList,
    meta: {
      containerFluid: true,
    },
  },
  {
    name: 'CertificateOfInsuranceAdd',
    path: '/certificatesOfInsurance/new',
    component: CertificateOfInsuranceRecord,
    meta: {
      containerFluid: true,
    },
  },
  {
    name: 'CertificateOfInsuranceEdit',
    path: '/certificatesOfInsurance/:id',
    component: CertificateOfInsuranceRecord,
    props: true,
    meta: {
      containerFluid: true,
    },
  },

  // Schemas
  // {
  //   name: 'SchemaList',
  //   path: '/schemas',
  //   component: SchemaList,
  //   meta: {
  //     containerFluid: true,
  //   },
  // },
  // {
  //   name: 'SchemaAdd',
  //   path: '/schemas/new',
  //   component: SchemaForm,
  // },
  // {
  //   name: 'SchemaEdit',
  //   path: '/schemas/:id',
  //   component: SchemaForm,
  //   props: true,
  // },
  // {
  //   name: 'TemplateSchemaList',
  //   path: '/templateSchemas',
  //   component: TemplateSchemaList,
  //   meta: {
  //     containerFluid: true,
  //   },
  // },
  // {
  //   name: 'TemplateSchemaAdd',
  //   path: '/templateSchemas/new',
  //   component: TemplateSchemaForm,
  // },
  // {
  //   name: 'TemplateSchemaEdit',
  //   path: '/templateSchemas/:id',
  //   component: TemplateSchemaForm,
  //   props: true,
  // },
  // Generic Forms
  // {
  //   name: 'CustomObjectList',
  //   path: '/objects/:schemaID',
  //   component: SchemaBasedList,
  //   props: true,
  //   meta: {
  //     containerFluid: true,
  //   },
  // },
  // {
  //   name: 'CustomObjectAdd',
  //   path: '/objects/:schemaID/new',
  //   component: SchemaBasedForm,
  //   props: true,
  // },
  // {
  //   name: 'CustomObjectEdit',
  //   path: '/objects/:schemaID/:id',
  //   component: SchemaBasedForm,
  //   props: true,
  // },
  // {
  //   name: 'TemplateCustomObjectAdd',
  //   path: '/templateObjects/:schemaID/new',
  //   component: TemplateSchemaBasedForm,
  //   props: true,
  // },
  // {
  //   name: 'TemplateCustomObjectEdit',
  //   path: '/templateObjects/:schemaID/:id',
  //   component: TemplateSchemaBasedForm,
  //   props: true,
  // },
  {
    name: 'PicklistList',
    path: '/picklists',
    component: PicklistList,
  },
  {
    name: 'TaskAdd',
    path: '/task/new',
    component: ActivityForm,
  },
  {
    name: 'TaskEdit',
    path: '/task/:id',
    component: ActivityForm,
    props: true,
  },
];
