import { getCurrentInstance, onBeforeUnmount } from 'vue';
import { createStoreInstance, IDisposableStore, StoreWithType } from '@/stores/storeFactory';

let componentCounter = 0;

export function useStore<T extends IDisposableStore>(storeDefinition: StoreWithType<T>): T {
  const key = getUniqueComponentKey();

  const store = createStoreInstance<T>(storeDefinition, key);

  onBeforeUnmount(() => {
    store.subscribers = store.subscribers.filter((subscriber: string) => subscriber !== key);

    if (store.subscribers.length === 0) {
      store.dispose();
    }
  });

  return store;
}

function getUniqueComponentKey(): string {
  const instance = getCurrentInstance();
  const componentName = instance?.proxy?.$options.name || 'UnnamedComponent';
  const componentId = componentCounter++;
  return `${componentName}-${componentId}`;
}
