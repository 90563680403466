import BaseAutocomplete from '@/components/base/BaseAutocomplete.vue';
import BaseCustomInput from '@/components/base/BaseCustomInput.vue';
import BaseDatePicker from '@/components/base/BaseDatePicker.vue';
import BaseFileManager from '@/components/base/BaseFileManager.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseRadioGroup from '@/components/base/BaseRadioGroup.vue';
import BaseTextArea from '@/components/base/BaseTextArea.vue';
import BaseTextList from '@/components/base/BaseTextList.vue';
import BaseRichTextEditor from '@/components/base/RichTextEditor/BaseRichTextEditor.vue';
import ViewBanner from '@/components/view_banner/ViewBanner.vue';

import { GenericDate, GenericDateTime } from '@/filters/date';
import momentPlugin from '@/plugins/moment';
import vuetify from '@/plugins/vuetify';
import { padNumber } from '@/services/utils/Utils';
import moment from 'moment-timezone';
import { PiniaVuePlugin, createPinia } from 'pinia';
import Vue from 'vue';
import autoProgress from 'vue-auto-progress';
import { DynamicReactiveRefs } from 'vue-reactive-refs';
import VueResource from 'vue-resource';
import VueRouter from 'vue-router';
import Vuelidate from 'vuelidate';
import { VuetifyTiptap, createVuetifyProTipTap } from 'vuetify-pro-tiptap';
import App from './App.vue';
import FileContainer from './components/base/FileContainer.vue';
import ImageContainer from './components/image/ImageContainer.vue';
import { GOOGLE_CLIENT_ID } from './config/constants';
import router from './router/router';
import { PermissionsService } from './services/auth/permissionsService';
import { CacheService } from './services/data/cache/cacheService';
import { ServiceContainer } from './services/serviceContainer';
import store from './store/index';
import { checkForNewVersions } from './utils/version-checker';

import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/analytics';
import { FIREBASE_CONFIG } from './config/firebase';

Vue.config.productionTip = false;

const cacheService = ServiceContainer.get(CacheService);
cacheService.initialize();

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(VueResource);
Vue.use(Vuelidate);
Vue.use(createVuetifyProTipTap({ vuetify, components: { VuetifyTiptap } }));
Vue.use(DynamicReactiveRefs);
Vue.use(momentPlugin);
Vue.use(VueRouter);

Vue.component('le-image', ImageContainer);
Vue.component('le-file', FileContainer);
Vue.component('BaseRadioGroup', BaseRadioGroup);
Vue.component('BaseAutocomplete', BaseAutocomplete);
Vue.component('BaseInput', BaseInput);
Vue.component('BaseTextArea', BaseTextArea);
Vue.component('BaseRichTextEditor', BaseRichTextEditor);
Vue.component('BaseDatePicker', BaseDatePicker);
Vue.component('BaseFileManager', BaseFileManager);
Vue.component('ViewBanner', ViewBanner);
Vue.component('BaseTextList', BaseTextList);
Vue.component('BaseCustomInput', BaseCustomInput);

Vue.filter('genericDate', GenericDate);
Vue.filter('genericDateTime', GenericDateTime);
Vue.filter('currency', (value: number) => value?.toLocaleString(undefined, { style: 'currency', currency: 'USD' }));
Vue.filter('percentage', (value: number) => value?.toLocaleString(undefined, { style: 'percent' }));
Vue.filter('recordNumber', (value: number, prefix: string, minWidth = 4) => `${prefix}-${padNumber(value, minWidth)}`);

Vue.prototype.$moment = moment;

// Load Google Maps API
const script = document.createElement('script');
script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.APP_GOOGLE_MAPS_API_KEY}&libraries=geometry,drawing,places&loading=async&callback=console.log`;
document.head.appendChild(script);

gapi.load('client', () => {
  gapi.client.init({
    discoveryDocs: [
      'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
      'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
      'https://sheets.googleapis.com/$discovery/rest?version=v4',
      'https://gmail.googleapis.com/$discovery/rest?version=v1',
      'https://docs.googleapis.com/$discovery/rest?version=v1',
    ],
  });
});

let app: Vue | undefined;

const firebaseApp = firebase.initializeApp(FIREBASE_CONFIG);
firebase.analytics();

if (process.env.NODE_ENV === 'development') {
  if (process.env.APP_FIREBASE_EMULATOR === 'true') {
    console.warn('Using Local Functions Emulator at http://localhost:5001/ 🚀');
    const functions = firebaseApp.functions();
    functions.useEmulator('localhost', 5001);
  } else {
    console.warn('Development Client - Production Backend');
  }
}

firebaseApp.auth().onAuthStateChanged((user) => {
  if (user) {
    // Initialize permissions up front so we can access synchronously later
    const permissionsService = ServiceContainer.get(PermissionsService);
    permissionsService.initialize();
  }

  if (!app) {
    app = new Vue({
      el: '#app',
      router,
      store,
      pinia,
      vuetify,
      components: { App, autoProgress },
      template: '<div><App/><auto-progress/></div>',
    });
  }
});

if (process.env.NODE_ENV === 'production') {
  checkForNewVersions();
}
