import firebase from 'firebase';
import { storageHandler } from '@/pages/shared/storageHandler';
import { currentUser, getContactFromRecord, systemFields } from '@/services/utils/Utils';
import { companyName } from '@/config/constants';

const state = {
  contacts: [],
  currentContact: {},
  subscription: null,
};

const getters = {
  contacts: ({ contacts }) => {
    const sorter = (a, b) => {
      if (a.fullName < b.fullName) {
        return -1;
      }
      if (a.fullName > b.fullName) {
        return 1;
      }
      return 0;
    };

    return contacts.slice().sort(sorter);
  },
  contactSubscription: ({ subscription }) => subscription,
  currentContact: ({ currentContact }) => currentContact,
  currentUser: () => {
    const auth = firebase.auth();
    const { uid, displayName, email, phoneNumber } = auth.currentUser;
    return { uid, displayName, email, phoneNumber };
  },
  contactsForPicklist: (state) => {
    return state.contacts.map((contact) => ({
      ...contact,
      name: contact.fullName,
    }));
  },
  contacts4picklist: (_, { contactsForPicklist }) => contactsForPicklist.map(getContactFromRecord),
  saveContact:
    () =>
    async (id, record, { driverLicenseImageFront, driverLicenseImageBack }) => {
      if (!(record && typeof record === 'object' && !Array.isArray(record))) {
        throw Error('invalid record type');
      }

      const storage = firebase.storage();
      const firestore = firebase.firestore();
      const contactsCollection = firestore.collection('contacts');
      const contactDocument = id ? contactsCollection.doc(id) : contactsCollection.doc();

      let driverLicenseBack = { ...record.driverLicenseImageBack };
      let driverLicenseFront = { ...record.driverLicenseImageFront };

      if (driverLicenseImageBack.file) {
        if (driverLicenseBack.url) {
          const ref = storage.refFromURL(driverLicenseBack.url);
          await ref.delete();
          console.log('Deleted Back');
        }

        const request = await storageHandler(
          [driverLicenseImageBack.file],
          `picture_uploads/contacts/${contactDocument.id}/driver_license`,
        );
        driverLicenseBack = request[0];
      }

      if (driverLicenseImageFront.file) {
        if (driverLicenseFront.url) {
          const ref = storage.refFromURL(driverLicenseFront.url);
          await ref.delete();
          console.log('Deleted Front');
        }

        const request = await storageHandler(
          [driverLicenseImageFront.file],
          `picture_uploads/contacts/${contactDocument.id}/driver_license`,
        );
        driverLicenseFront = request[0];
      }

      await contactDocument.set({
        ...record,
        driverLicenseImageBack: driverLicenseBack,
        driverLicenseImageFront: driverLicenseFront,
      });

      return contactDocument.id;
    },
  updateContact: () => (id, data) => {
    if (!id || !data || typeof data !== 'object') {
      throw Error('invalid input');
    }

    const firestore = firebase.firestore();
    const contactsCollection = firestore.collection('contacts');
    const contactsDocument = contactsCollection.doc(id);
    return contactsDocument.update({
      ...data,
      modifiedBy: currentUser().uid,
      modifiedDate: new Date().toISOString(),
    });
  },
  deleteContact: () => (id) => {
    if (!id || !id.length) {
      throw Error('invalid input');
    }

    const firestore = firebase.firestore();
    const contactdCollection = firestore.collection('contacts');
    const contactDocument = contactdCollection.doc(id);

    return contactDocument.delete();
  },
  // Takes the firebase user's uid and looks for a contact with this id
  getContactByWebAppUser() {
    return function (uid) {
      const firestore = firebase.firestore();
      const contactsCollection = firestore.collection('contacts');
      const contactsQuery = contactsCollection.where('user.id', '==', uid);
      return contactsQuery.get();
    };
  },
};

const mutations = {
  setCurrentContact(state, [currentContact]) {
    state.currentContact = { ...currentContact };
  },
  setContacts(state, contacts) {
    if (!Array.isArray(contacts)) {
      return [];
    }
    state.contacts = [...contacts];
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
};

const actions = {
  getContacts({ state, commit }) {
    const firestore = firebase.firestore();
    const contactsCollection = firestore.collection('contacts');

    if (state.subscription) {
      return;
    }

    const subscription = contactsCollection.onSnapshot((querySnapshot) => {
      const contacts = [];
      querySnapshot.forEach((doc) => {
        contacts.push({
          ...doc.data(),
          id: doc.id,
        });
      });

      commit('setContacts', contacts);
    });

    commit('SET_SUBSCRIPTION', subscription);
  },
  getCurrentContact({ commit, getters, rootGetters }) {
    const auth = firebase.auth();
    const { uid } = auth.currentUser;

    getters
      .getContactByWebAppUser(uid)
      .then((response) => {
        commit('setCurrentContact', rootGetters['utils/querySnapshotToArray'](response));
      })
      .catch((err) => {
        console.log(err);
        swal('Error!', `Something went wrong when retrieving current user, please contact to ${companyName}'s IT Department`, 'error');
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
