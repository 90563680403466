export class LocalStorageService {
  private trueString: string = 'true';
  private falseString: string = 'false';

  /**
   * Fetcha local storgae value. Will be a string
   * @param key - The key to retrieve from local storage
   * @returns
   */
  public getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  /**
   * Fetch a local Storage value as a boolean
   * @param key - The key to retrieve from local storage
   * @returns
   */
  public getItemAsBoolean(key: string): boolean {
    return this.getItem(key) === this.trueString;
  }

  /**
   * Fetch a local Storage value as a number
   * @param key - The key to retrieve from local storage
   * @returns
   */
  public getItemAsNumber(key: string): number | null {
    const numberVal = this.getItem(key);

    if (numberVal === null) {
      return null;
    } else if (isNaN(Number(numberVal))) {
      throw Error(`Value for key ${key} is not a number`);
    } else {
      return Number(numberVal);
    }
  }

  /**
   * Set a local Storage value. Infers type
   * @param key - The key to set in local storage
   * @param value - The value to set. Supports boolean types, numerical types and strings
   */
  public setItem(key: string, value: string | boolean | number): void {
    if (typeof value === 'boolean') {
      localStorage.setItem(key, value ? this.trueString : this.falseString);
    } else {
      localStorage.setItem(key, value);
    }
  }

  /**
   * Remove an item from local storage
   * @param key - The key item to remove from local storage
   */
  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  /**
   * Clear all local storage values
   */
  public clear(): void {
    localStorage.clear();
  }

  /**
   * Determine how many keys we have in local storage
   */
  public length(): number {
    return localStorage.length;
  }
}
