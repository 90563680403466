import { FirestorePicklistCollection, PicklistEntry } from '@/models/picklist';
import { FirestoreService } from '@/services/data/firebase/db/firestoreService';
import { Unsubscribe } from '@/services/data/firebase/db/iFirestoreService';
import { ServiceContainer } from '@/services/serviceContainer';
import { picklistFormatToArray } from '@/services/utils/Utils';
import { defineDisposableStore, IDisposableStore } from './storeFactory';
import { Deferred } from '@/core/helpers/deferredPromise';

interface IPicklistStore extends IDisposableStore {
  picklists: Record<string, PicklistEntry[]>;
  getPicklist: (id: string) => PicklistEntry[];
}

interface PicklistState {
  picklists: Record<string, PicklistEntry[]>;
  unsubscribe: Unsubscribe | undefined;
  subscribers: string[];
}

const firestoreService = ServiceContainer.get(FirestoreService);

export const PicklistStore = defineDisposableStore<PicklistState, IPicklistStore>({
  id: 'picklist',

  state: (): PicklistState => ({
    picklists: {},
    unsubscribe: undefined,
    subscribers: [],
  }),

  getters: {
    getPicklist: (state) => (id: string) => state.picklists[id] || [],
  },

  actions: {
    async initializePicklists(): Promise<void> {
      if (this.unsubscribe) return;

      const deferred = Deferred.promise();

      this.unsubscribe = firestoreService.subscribeToCollection('picklistsNew2', (allPicklists: FirestorePicklistCollection[]) => {
        allPicklists.forEach((picklist) => {
          this.picklists[picklist.id] = picklistFormatToArray(picklist.elements);
        });
        deferred.resolve();
      });

      return deferred.promise;
    },

    dispose(): void {
      if (this.unsubscribe) {
        this.unsubscribe();
      }
    },
  },
});
