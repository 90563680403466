import { defineStore, StoreDefinition } from 'pinia';

export interface IDisposableStore {
  dispose: () => void;
  subscribers: string[];
}

interface DisposableStoreDefinition<State, PublicInterface extends IDisposableStore> {
  id: string;
  state: () => State & { subscribers: string[] };
  getters?: {
    [key: string]: (state: State) => unknown;
  } & ThisType<State & PublicInterface>;
  actions: {
    dispose: () => void;
    [key: string]: unknown;
  } & ThisType<State & PublicInterface>;
}

export type StoreWithType<T> = StoreDefinition & { $type: T };

/**
 * Wraps store definition to enforce the IDisposableStore interface
 * @typeParam State - The internal state of the store, available to the store itself
 * @typeParam PublicInterface - The public interface of the store, as exposed to the component
 * @returns A store definition conforming to the type PublicInterface
 */
export function defineDisposableStore<State, PublicInterface extends IDisposableStore>(
  options: DisposableStoreDefinition<State, PublicInterface>,
): StoreWithType<PublicInterface> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return defineStore(options.id, options as any) as StoreWithType<PublicInterface>;
}

/**
 * Creates a store instance from a definition
 * @param storeDefinition - The store definition
 * @param componentKey - Unique key for the component using the store
 * @returns A singleton store instance
 */
export function createStoreInstance<T extends IDisposableStore>(storeDefinition: StoreWithType<T>, componentKey: string): T {
  // Pinia lazy-builds the store instance as a singleton
  const store = storeDefinition();

  if (!store.subscribers) {
    store.subscribers = [];
  }

  store.subscribers.push(componentKey);

  return store as T;
}
